import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day1: Content[] = [
    {
        id: "577be84c-c81e-4c34-8443-aabe33f1d6a0",
        text: [
            "Hi <Nick Name>! Welcome to wMammogram project."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Research Information 2.mp4"),
            },
            drive: {
                sd: "1IH7iK0dombb99uWp92zY3pbIeCrJuXg7",
                hd: "152pOGPP69y3WX2hTFMiz1YOJzEgtQ6pX",
                uhd: "1rcu1NYnDzjqdy-iJ8DDfLV5inj4BeXnK"
            }
        },
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        },
        textStyle: [
            ["text-h5", "text-center"]
        ]
    },
    {
        id: "ed330286-bd22-44c7-bcd7-e3d15aaec385",
        text: [
            "For the next 7 days, you will learn about the definition of breast cancer, the ways of preventing breast cancer and the types of breast cancer screening.",
            "The major goal of this program is to help you receive a mammogram in free or with low price and without complicated procedure.",
        ]
    },
    {
        id: "575e0a35-11f7-4896-8ea9-b11600a3ab4e",
        text: [
            "Is this your situation that you don’t have health insurance that partially or completely covers mammograms?",
            "No worries!",
            "There is a program called ALL WOMEN COUNT that supports free breast cancer screening by the South Dakota State government. You can learn more about the ALL WOMEN COUNT program in Day 4."
        ],
        pinkie: {
            src: require("@/assets/pinkie/dance.png"),
            right: true,
            animation: "pinkie-dance"
        }
    },
    {
        id: "f588307b-675c-4f03-b465-ce4465091c11",
        text: [
            "Don’t know how to use your health insurance to receive a mammogram? No worries!",
            "Our American Indian health navigator will help you to figure out how to use your health insurance to receive a mammogram."
        ],
        pinkie: {
            src: require("@/assets/pinkie/cheer.png"),
            left: true,
            animation: "pinkie-cheer"
        }
    },
    {
        id: "20f7d077-b6f6-41c6-9fc9-9a96d093cfb6",
        text: [
            "Do you happen to need clinic information, transportation, or other services to receive a mammogram?",
            "Guess what?",
            "Our American Indian health navigator will provide those services. Yeah!"
        ],
        pinkie: {
            right: true,
            src: require("@/assets/pinkie/wink.png")
        }
    },
    {
        id: "f0c742f0-08eb-43f4-92f5-09f6c2687505",
        text: [
            "Please click the following phone shaped emoticon tel:605-202-1418 605-202-1418 located in the top right corner anytime, if you decide to receive a mammogram or if you want to talk about it with our American Indian health navigator while you are in our program."
        ]
    },
    {
        id: "c235a73c-5385-4253-ab6d-4ddfc232c78f",
        text: [
            "For your information, our program contains several quizzes.",
            "If you answer those questions, you will earn pink ribbons and get some rewards according to the number of the ribbons.",
            "Please response to all questions.",
            "All right, are you ready to start?!"
        ],
        pinkie: {
            src: require("@/assets/pinkie/drum.png"),
            right: true,
            animation: "pinkie-shake"
        }
    },
    {
        id: "0f2790b8-6e32-413e-8980-b31f56feec26",
        text: [
            "American Indian women show one of the highest mortality rates across all racial/ethnic groups in the United States. Aren’t you surprised by this fact?",
            "Sue Reisch, RN will talk about the way to reduce the mortality rate of breast cancer for American Indian women.",
            "Please click here to watch the interview video."
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Nurseswelcome.mp4"),
            },
            drive: {
                sd: "1WcGWi1_9EbfI8Z0-9iJAP743ZDlExFck",
                hd: "18MHpcGyqpzwC9sLnVlCcBME7tzdNQOq4",
                uhd: "1FQb7IDOvLoTOvCE5m0p6P_iPLnm4V8BY"
            }
        }
    },
    {
        id: "c5f1136c-a742-407c-863b-039269d7fbef",
        text: [
            "Sue Reisch, RN says that if a breast cancer is detected earlier, the treatment would be easier. She also emphasizes getting a mammogram regularly for the early detection of breast cancer."
        ],
        pinkie: {
            src: require("@/assets/pinkie/idea.png"),
            left: true
        }
    },
    {
        id: "a4f3bb3c-fe35-402e-b408-d1d6481abe24",
        text: [
            "Why don’t we watch Ms. Brooks’ video at this point?",
            "Ms. Brooks would have been able to find her Brest cancer early because she has received mammograms regularly. Her treatments were done successfully. Denise Brooks video"
        ],
        video: {
            srcset: {
                hd: require("@/assets/videos/Survivor Denise B.mp4"),
            },
            drive: {
                sd: "155MwEU3hgHf-O_5QkPO8WxZ90Jah2hNr",
                hd: "1-rIUc1tLoJ9atPcOp0ABcgsZSoismsSi",
                uhd: "1QFrLZdJp01CA3aO9iVyOPOCPZ5zKphns"
            }
        }
    },
    {
        id: "dd24a171-7cf7-45c7-b91f-006bad757f2c",
        text: [
            "If Ms. Brooks does not receive a mammogram regularly, what might happen to her?",
            "We believe that you know why it is important to receive a regular mammogram."
        ]
    },
    {
        id: "005e8d2d-6ce8-41d9-a5a8-71887464406d",
        text: [
            "We will show you a photo taken by mammogram.",
            "Do you see the mass in the below photo?",
            "The mass proved as breast cancer."
        ],
        img: {
            src: require("@/assets/cancer.png"),
            cols: 6
        },
        question: {
            question: "Do you think breast cancer is always palpable because it is a mass?",
            answers: [
                "Yes",
                "No"
            ],
            text: [
                [
                    "In fact, breast cancer may not be palpable.",
                    "At the same time, any masses that you can feel in your breast are not necessarily cancer.",
                    "However, note that those masses may turn into cancer as time goes by."
                ],
                [
                    "Good job! Breast cancer may not be palpable.",
                    "At the same time, any masses that you can feel in your breast are not necessarily cancer.",
                    "However, note that those masses may turn into cancer as time goes by."
                ]
            ],
            correct: 1,
            time: 0
        }
    },
    {
        id: "b9118932-d3f0-42b0-9c73-6e3123b540fc",
        text: [
            "What are the risk factors for breast cancer? The risk factors for breast cancer include being overweight, drinking alcohol, getting less exercise, and also fatty diets."
        ],
        question: {
            question: "What do you think may be other factors?",
            answers: [
                "Having no birth history or the first birth after the age of 30",
                "Getting older",
                "Having family history of breast cancer",
                "All of the above"
            ],
            correct: 3,
            text: [
                ["Nice try 😉, but all factors are related to an occurrence of breast cancer."],
                ["Nice try 😉, but all factors are related to an occurrence of breast cancer."],
                ["Nice try 😉, but all factors are related to an occurrence of breast cancer."],
                [
                    "Great!!! 👏",
                    "All factors are related to an occurrence of breast cancer."
                ]
            ],
            time: 0
        }
    },
    {
        id: "e9fea153-e603-4de7-b5f8-0f896abe4418",
        text: [
            "Today’s take home message is that American Indian women have high risk for breast cancer due to the western lifestyle, including high fat intake and less physical activity. This is why having breast cancer screening early is very important!",
            "However, there is a risk for breast cancer even if you have healthy Indigenous meals.",
            "Thus, getting a mammogram regularly is very important."
        ]
    },
    {
        id: "576bbce6-9e27-4a79-a9be-6f911ee4691a",
        text: [
            "Tomorrow we will talk about how we can prevent and detect breast cancer."
        ],
        question: {
            question: "Are you looking forward to tomorrow’s messages?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 0,
            time: 0,
            text: [
                [
                    "Great!"
                ],
                [
                    "Too bad! Cheer up!"
                ]
            ],
            common: [
                "We promise you that this program will become more interesting. 🎗️",
                "If you want to chat more about today’s topic, text or call us back, 605-202-1418.",
                "You will receive a notification later today which will inform you how many pink ribbons you have received today. Thanks for your hard work today!"
            ]
        }
    }
];

export const manager = new ContentManager(day1);

export default day1;